<section class="contenido" style=" margin-top: 100px;">
  <div class="conten-cont" style="">
    <!-- <p class="texto-azul-desk" style="font-size: 1.5em;"><strong>¡COMPRA Y RECOGE EN ALMACÉN CHIHUAHUA!</strong></p> -->
    <div class="container-1" style="justify-content: center; align-items: flex-start; position: relative;">
      <!-- Imagen del Banner -->
      <div class="banner-container"
        style="align-items: center;width: auto;height: auto; position: relative; display: block;">
        <img src="/assets/img/conoce-mas-de-quelli/LandingpageQuelli-PC.webp" alt="" style="width: 100%; height: auto;">
      </div>
      <div class="banner-container"
        style="position: absolute; top: 70%; left: 48%; transform: translate(-88%, -50%); display: flex; gap: 3em;">
        <div class="texto-sobre-imagen" style="text-align: center;">
          <a style="display: flex; justify-content: center;" href="https://www.instagram.com/quelliproducts/"><img
              src="/assets/img/conoce-mas-de-quelli/LandingpageQuelliBOTONES-03.webp" alt="" style="width: 65%;"></a>
          <span class="redes" style="display: block; margin-top: 0.7em; color: #FFF; font-size: 2em;">Visítanos en
            Instagram</span>
        </div>
        <div class="texto-sobre-imagen" style="text-align: center;">
          <a style="display: flex; justify-content: center;" href="https://www.tiktok.com/@quelli.products"><img
              src="/assets/img/conoce-mas-de-quelli/LandingpageQuelliBOTONES-04.webp" alt="" style="width: 65%;"></a>
          <span class="redes" style="display: block; margin-top: 0.7em; color: #FFF; font-size: 2em;">Visítanos en
            TikTok</span>
        </div>
        <div class="texto-sobre-imagen" style="text-align: center;">
          <a style="display: flex; justify-content: center;" href="https://lideart.com.mx/catalogo/2043/quelli"><img
              src="/assets/img/conoce-mas-de-quelli/LandingpageQuelliBOTONES-05.webp" alt="" style="width: 65%;"></a>
          <span class="redes" style="display: block; margin-top: 0.7em; color: #FFF; font-size: 2em;">Conoce
            nuestros productos</span>
        </div>
      </div>
      <div class="banner-containerMovil"
        style="align-items: center;width: auto;height: auto; position: relative; display: none;">
        <img class="imagen-movil" src="/assets/img/conoce-mas-de-quelli/LandingpageQuelli-MOV.webp" alt=""
          style="display: block;">
        <img class="imagen-movil2" src="/assets/img/conoce-mas-de-quelli/LandingpageQuelli-MOV.webp" alt=""
          style="display: none;">
        <div class="texto-redes"
          style="position: absolute; top: 42%; left: 50%; transform: translate(-50%, -50%); display: flex; gap: 1.5em; align-items: flex-start; width: 80%;">
          <div class="texto-sobre-imagen1"
            style="display: flex; flex-direction: column; align-items: center; text-align: center;">
            <a style="display: flex; justify-content: center;" href="https://www.instagram.com/quelliproducts/"><img
                src="/assets/img/conoce-mas-de-quelli/LandingpageQuelliBOTONES-03.webp" alt="" style="width: 65%;"></a>
            <span class="redes" style="display: block; margin-top: 0.7em; color: #FFF; font-size: 1.6em;">Visítanos en
              Instagram</span>
          </div>
          <div class="texto-sobre-imagen2"
            style="display: flex; flex-direction: column; align-items: center; text-align: center;">
            <a style="display: flex; justify-content: center;" href="https://www.tiktok.com/@quelli.products"><img
                src="/assets/img/conoce-mas-de-quelli/LandingpageQuelliBOTONES-04.webp" alt="" style="width: 65%;"></a>
            <span class="redes" style="display: block; margin-top: 0.7em; color: #FFF; font-size: 1.6em;">Visítanos en
              TikTok</span>
          </div>
          <div class="texto-sobre-imagen3"
            style="display: flex; flex-direction: column; align-items: center; text-align: center;">
            <a style="display: flex; justify-content: center;" href="https://lideart.com.mx/catalogo/2043/quelli"><img
                src="/assets/img/conoce-mas-de-quelli/LandingpageQuelliBOTONES-05.webp" alt="" style="width: 65%;"></a>
            <span class="redes" style="display: block; margin-top: 0.7em; color: #FFF; font-size: 1.6em;">Conoce
              nuestros
              productos</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>